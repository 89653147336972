import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
/* import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial'; */

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            {/* <FooterSocial /> */}
            <Logo />
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            {/* <FooterNav /> */}
            {/* <div className="footer-copyright">Hecho en México <br/> por <a href="https://tectonico.com.mx">Tectónico</a>.</div> */}
            <div className="footer-copyright" style={{marginLeft:'20px'}}><a href="https://goo.gl/maps/MPz4kAu2Lh1eZkpA9" target="blank" rel='noreferrer'><strong>Av. Ocho de Julio No. 720</strong><br/>Colonia Mexicalzingo<br/>Guadalajara, Jalisco.</a> <br/><br/><a href="tel:+52 33 3614 2327"><strong>Llámanos:</strong> +52 33 3614 2327</a><br/><br/>Derechos reservados.</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;