import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import './slides.css'

export const HeroSlide = () => {
    
    const images = [
        require('./../../../assets/images/hero/0_Hero_01.jpg'),
        require('./../../../assets/images/hero/0_Hero_02.jpg'),
        require('./../../../assets/images/hero/0_Hero_03.jpg'),
    ];

    const buttonStyle = {
        width: "30px",
        background: 'none',
        border: '0px'
    };
    
    const properties = {
        prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
        nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
    }

    /* const responsiveSettings = [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ]; */
    
    return (
        <>
            <Slide duration={5000} {...properties}>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                        {/* <span>Slide 1</span> */}
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                        {/* <span>Slide 2</span> */}
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                        {/* <span>Slide 3</span> */}
                    </div>
                </div>
            </Slide>
        </>
    )
}
