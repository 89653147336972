import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { FeatureTilesSlide } from './partials/FeatureTilesSlide';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const [tileModalActive, setTileModalActive] = useState(false);
  const [activeTile, setActiveTile] = useState(0);

  const openModal = (e) => {
    e.preventDefault();
    setActiveTile(e.target.getAttribute('data-tile'));
    setTileModalActive(true);
  }
  
  const closeModal = (e) => {
    e.preventDefault();
    setActiveTile(0);
    setTileModalActive(false);
  }   

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Nuestra especialidad',
    paragraph: ''
  };

  /* const tileInfo = [{
    title: 'Inagururación de Obras',
    icon: 'Icon_001.png',
    description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.'
  }]; */

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Modal
        id="prod-modal-01"
        show={tileModalActive}
        handleClose={closeModal}
        >
          <FeatureTilesSlide activeTile={activeTile}/>
        </Modal>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            {/* {
              tileInfo.map(( tile ) => {
                <div key={tile.title}>{ tile.title }</div>
              })
            } */}

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a data-tile="1" href="#sp-1" onClick={openModal} >
                      <Image
                        src={require('./../../assets/images/original/Icon_001.png')}
                        alt="Features tile icon 01"
                        data-tile={1}
                        width={64}
                        height={64} />
                      </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8" style={{textTransform: 'capitalize!important'}}>
                    Inagururación de Obras
                  </h4>
                  <p className="m-0 text-sm">
                    {''}
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a data-tile="2" href="#sp-2" onClick={openModal} >
                      <Image
                        src={require('./../../assets/images/original/Icon_002.png')}
                        alt="Features tile icon 02"
                        data-tile={2}
                        width={64}
                        height={64} />
                      </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Para Transformadores
                  </h4>
                  <p className="m-0 text-sm">
                    {''}
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a data-tile="2" href="#sp-2" onClick={openModal} >
                      <Image
                        src={require('./../../assets/images/original/Icon_003.png')}
                        alt="Features tile icon 03"
                        data-tile={3}
                        width={64}
                        height={64} />
                      </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Uso Industrial
                    </h4>
                  <p className="m-0 text-sm">
                    {''}
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a data-tile="4" href="#sp-4" onClick={openModal} >
                      <Image
                        src={require('./../../assets/images/original/Icon_004.png')}
                        alt="Features tile icon 04"
                        data-tile={4}
                        width={64}
                        height={64} />
                      </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Con código QR
                    </h4>
                  <p className="m-0 text-sm">
                    {''}
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a data-tile="5" href="#sp-5" onClick={openModal} >
                      <Image
                        src={require('./../../assets/images/original/Icon_005.png')}
                        alt="Features tile icon 05"
                        data-tile={5}
                        width={64}
                        height={64} />
                      </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Publicidad
                    </h4>
                  <p className="m-0 text-sm">
                    {''}
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <a data-tile="5" href="#sp-5" onClick={openModal} >
                      <Image
                        src={require('./../../assets/images/original/Icon_006.png')}
                        alt="Features tile icon 06"
                        data-tile={6}
                        width={64}
                        height={64} />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Para Muebles
                    </h4>
                  <p className="m-0 text-sm">
                    {''}
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;