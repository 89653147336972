import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { TileSlide } from './TileSlide';

import './tileSlides.css'

export const FeatureTilesSlide = ( { activeTile = 1 } ) => {
    
    const images = [
        [
            require('./../../../assets/images/tiles/1_Tiles_04.jpg'),
            require('./../../../assets/images/tiles/1_Tiles_03.jpg'),
        ],
        [
            require('./../../../assets/images/tiles/1_Tiles_02.jpg'),
        ],
        [
            require('./../../../assets/images/tiles/1_Tiles_01.jpg'),
            require('./../../../assets/images/tiles/1_Tiles_02.jpg'),
            require('./../../../assets/images/tiles/1_Tiles_07.jpg'),
        ],
        [
            require('./../../../assets/images/tiles/1_Tiles_06.jpg'),
            require('./../../../assets/images/tiles/1_Tiles_07.jpg'),
        ],
        [
            require('./../../../assets/images/tiles/1_Tiles_08.jpg'),
            require('./../../../assets/images/tiles/1_Tiles_09.jpg'),
        ],
        [
            require('./../../../assets/images/tiles/1_Tiles_05.jpg'),
        ],
    ];

    /* const images = [
        require('./../../../assets/images/tiles/1_Tiles_04.jpg'),
        require('./../../../assets/images/tiles/1_Tiles_03.jpg'),
    ]; */

    const imagestoshow = images[activeTile-1];

    console.log(activeTile, imagestoshow.length, imagestoshow);
    
  return (
    <div>
        <Slide>
            {/* <TileSlide imgURL={ imagestoshow[0] } /> */}
        { imagestoshow.map((image, index) => (<TileSlide key={index} imgURL={ image } /> )) }
            
        </Slide>
    </div>
  )

}
